import React from 'react';
import { Card } from '../Card/card';
import { CardGroup } from '../CardGroup/cardGroup';
import RichText from '../RichText/richText';
import { Text } from '../../elements/Text/text.js';
import { Image } from '../../elements/Image/image';
import classNames from 'classnames';
import './compare.scss';

export const Compare = (props) => {
  const classnames = classNames('m-compare');
  const classnamesFirst = classNames('m-compare--firstBlock');
  const classnamesSecond = classNames('m-compare--secondBlock');

  const imageFirstColumn1 = require(`../../../images/shared/image-compare-firstcolumn-1.png`).default;
  const imageFirstColumn2 = require(`../../../images/shared/image-compare-firstcolumn-2.png`).default;
  const imageSecondColumn1 = require(`../../../images/shared/image-compare-secondcolumn-1.png`).default;

  return (
    <div className={classnames}>
      <CardGroup>
        {props.data.map((item, i) => {
          return (
            <Card
              col={12}
              colMd={6}
              key={i}
              classNames={i === 0 ? classnamesFirst : classnamesSecond}
              footerContent={item.headText && <Text color="white" content={item.headText} />}
            >
              {i === 0 ? (
                <>
                  <Image src={imageFirstColumn1} />
                  <Image src={imageFirstColumn2} />
                </>
              ) : (
                <>
                  <Image src={imageSecondColumn1} />
                </>
              )}
              {item.bodyText && <RichText alignCenter medium content={item.bodyText} />}
            </Card>
          );
        })}
      </CardGroup>
    </div>
  );
};
