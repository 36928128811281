import React from 'react';
import classNames from 'classnames';
import './secureSection.scss';
import { Icon } from '../../elements/Icon/icon';
import { Title } from '../../elements/Title/title';
import { Text } from '../../elements/Text/text';
import Section from '../../structures/Section/section';
import Wrapper from '../../structures/Wrapper/wrapper.js';

export const SecureSection = (props) => {
  const classnames = classNames(`m-secure`, {});
  const iconMoneyBack100 = require(`../../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/money-back-100.png`).default;
  const templateStyle = require(`../../../templates/${process.env.TEMPLATE}/structure/style-scheme-${process.env.STYLE_SCHEME}.json`);
  const secureVersion = templateStyle.product.secureVersion;

  return (
    <>
      {secureVersion === '1' ? (
        <Section bgPr centered padSmall name={props.sectionSecure.contentfulid}>
          <Wrapper middle>
            <div className={classnames}>
              <Icon margBottom icon={iconMoneyBack100} width={73} height={73} />
              <Text color="white" content={`${props.sectionSecure.content[0].text}`} />
            </div>
          </Wrapper>
        </Section>
      ) : (
        <div className={classnames}>
          <Section sc1 centered name={props.sectionSecure.contentfulid}>
            <Wrapper middle>
              <Title
                color="grey"
                fontWeight="normal"
                fontStyle={'centered'}
                size="tiny"
                content={props.sectionSecure.subtitle}
              />
              <Title tag="h2" size="regular" fixWidth content={props.sectionSecure.title} />
              <Icon margTop icon={iconMoneyBack100} width={73} height={73} />
            </Wrapper>
          </Section>
        </div>
      )}
    </>
  );
};
