import React, { useEffect } from 'react';
import Layout from '../../components/structures/Layout/layout.js';
import Dropdown from '../../components/modules/Dropdown/dropdown.js';
import contentFilter from '../../services/contentFilter/contentFilter.js';
import { Title } from '../../components/elements/Title/title.js';
import Section from '../../components/structures/Section/section';
import Wrapper from '../../components/structures/Wrapper/wrapper.js';
import { Button } from '../../components/elements/Button/button';
import { Card } from '../../components/modules/Card/card.js';
import { CardGroup } from '../../components/modules/CardGroup/cardGroup.js';
import { Jumbotron } from '../../components/modules/Jumbotron/jumbotron';
import RichText from '../../components/modules/RichText/richText';
import sticky from '../../services/sticky/sticky.js';
import getParameterUrl from '../../services/getParameterUrl/getParameterUrl';
import setCookie from '../../services/cookie/setCookie';
import { ReminderForm } from '../../components/modules/ReminderForm/reminderForm';
import { Steps } from '../../components/modules/Steps/steps.js';
import { ModalWrapper } from '../../components/modules/Modal/ModalWrapper.js';
import { useModal } from '../../hooks/useModal.js';
import { SecureSection } from '../../components/modules/SecureSection/secureSection.js';
import { Compare } from '../../components/modules/Compare';
import { TextMediaRows } from '../../components/modules/TextMediaRows';

const Product = (props) => {
  const location = props.path;
  const sections = props.pageContext.sections;
  const shortName = props.pageContext.shortName;
  const templateStyle = props.pageContext.templateStyle;
  const dataSite = props.pageContext.dataSite;
  const jumbotronContent = templateStyle.product.jumbotronContent
    ? '-' + templateStyle.product.jumbotronContent
    : '';

  const sectionInfobox = contentFilter(sections, 'infobox');
  const sectionJumbotron = contentFilter(sections, `jumbotron-pp-${shortName}${jumbotronContent}`);
  const sectionJumbotronSc5 = contentFilter(
    sections,
    `jumbotron-pp-sc5-${shortName}${jumbotronContent}`,
  );
  const sectionSteps = contentFilter(sections, 'steps');
  const sectionStepsServices = contentFilter(sections, 'steps-services');
  const sectionFaqs = contentFilter(sections, `faqs-${shortName}`);
  const sectionSecure = contentFilter(sections, 'secure');
  const sectionCompare = contentFilter(sections, 'compare');
  const sectionApplications = contentFilter(sections, 'all-applications');
  const sectionInfoContent = contentFilter(sections, 'info');

  const [isOpened, setIsOpened, modalLink, setModalLink] = useModal();

  useEffect(() => {
    sticky();
    let utm_source = getParameterUrl('utm_source');
    let utm_medium = getParameterUrl('utm_medium');
    let utm_campaign = getParameterUrl('utm_campaign');
    let cprice = getParameterUrl('cprice');
    let utm_content = getParameterUrl('utm_content');
    let utm_ab = getParameterUrl('utm_ab');
    utm_source !== '' && setCookie('utm_source', utm_source, 30);
    utm_medium !== '' && setCookie('utm_medium', utm_medium, 30);
    utm_campaign !== '' && setCookie('utm_campaign', utm_campaign, 30);
    cprice !== '' && setCookie('cprice', cprice, 30);
    utm_content !== '' && setCookie('utm_content', utm_content, 30);
    utm_ab !== '' && setCookie('utm_ab', utm_ab, 30);
  }, []);

  useEffect(() => {
    setModalLink(`/${sectionJumbotron.content[0].firstColumn[0].btnLink}`);
  });

  const jumbotronBg = require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/jumbo-product.jpg`).default;
  const jumbotronBgSc5 = require(`../../images/shared/jumbo-background-sc5.jpg`).default;
  const jumbotronVersion = templateStyle.product.jumbotronVersion;
  const allApplications = templateStyle.product.allApplications;
  const stepServices = templateStyle.product.stepServices;
  const sectionAdvantagesCompare = templateStyle.product.sectionAdvantagesCompare;
  const sectionInfo = templateStyle.product.sectionInfo;

  return (
    <Layout
      nav
      typeHeader={templateStyle.header.type}
      pageContext={props.pageContext}
      location={location}
      footer={templateStyle.footer.type}
      prefooter={templateStyle.prefooter.type}
      dataSite={dataSite}
    >
      <ModalWrapper
        name="popup-exit"
        type="modal-exit"
        directions={['top']}
        cookie={true}
        cookieExpire={1}
        iconType={false}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        modalLink={modalLink}
        setModalLink={setModalLink}
        typeExit
      />

      <Section pad0 name={sectionInfobox.contentfulid}>
        <Dropdown
          alert
          closeBox
          trigger={sectionInfobox.content[0].title.title}
          contentBody={sectionInfobox.content[0].content && <RichText content={sectionInfobox.content[0].content} />
          }
        />
      </Section>

      <Section padSmall white shadow jsSticky="top" jsStickyResponsive="bottom">
        <Wrapper narrow>
          <ReminderForm />
        </Wrapper>
      </Section>

      {jumbotronVersion === '1' ? (
        <Section pad0 name={sectionJumbotron.contentfulid}>
          <Jumbotron
            jumbotronBg={jumbotronBg}
            limitWidthFirstCol
            topSpaceMin
            bottomSpaceMin
            hideSmFirst
            flexFirstCenter
            flexSecondColumnCenter
            firstColumn={
              <img
                className="e-img__jumbotron"
                width={`348px`}
                style={{}}
                src={require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/product-${shortName}.png`).default}
                alt=""
              />
            }
            secondColumn={
              <>
                <Title
                  size="small"
                  margin={'margBot0'}
                  content={sectionJumbotron.content[0].firstColumn[0].headText}
                />
                <Title
                  size="large"
                  margin={'margBot0'}
                  content={sectionJumbotron.content[0].firstColumn[0].name}
                />
                {sectionJumbotron.content[0].firstColumn[0].formText && (
                  <Title
                    size="small"
                    content={sectionJumbotron.content[0].firstColumn[0].formText}
                  />
                )}
                {sectionJumbotron.content[0].firstColumn[0].bodyText && <RichText
                  content={sectionJumbotron.content[0].firstColumn[0].bodyText}
                  margBotAuto
                />}
              </>
            }
            fullColumn={
              <Button
                type="brand"
                size="large"
                iconArrow
                centered
                dataqa={sectionJumbotron.content[0].firstColumn[0].dataqa}
                link={`/` + sectionJumbotron.content[0].firstColumn[0].btnLink}
                content={sectionJumbotron.content[0].firstColumn[0].btnText}
              />
            }
          />
        </Section>
      ) : jumbotronVersion === '2' ? (
        <Section padBottom0 name={sectionJumbotron.contentfulid}>
          <Jumbotron
            firstColumn={
              <>
                <Title
                  margin={'margBot0'}
                  size="small"
                  content={sectionJumbotron.content[0].firstColumn[0].headText}
                />
                <Title
                  margin={'margBot0'}
                  content={sectionJumbotron.content[0].firstColumn[0].name}
                />
                {sectionJumbotron.content[0].firstColumn[0].formText && (
                  <Title
                    size="small"
                    content={sectionJumbotron.content[0].firstColumn[0].formText}
                  />
                )}
                {sectionJumbotron.content[0].firstColumn[0].bodyText && <RichText
                  content={sectionJumbotron.content[0].firstColumn[0].bodyText}
                  margBotAuto
                />}
                <Button
                  type="brand"
                  size="large"
                  iconArrow
                  dataqa={sectionJumbotron.content[0].firstColumn[0].dataqa}
                  link={`/` + sectionJumbotron.content[0].firstColumn[0].btnLink}
                  content={sectionJumbotron.content[0].firstColumn[0].btnText}
                />
              </>
            }
            secondColumn={
              <div>
                <img
                  className="e-img__jumbotron"
                  width={`348px`}
                  src={require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/product-${shortName}.png`).default}
                  alt=""
                />
              </div>
            }
          />
        </Section>
      ) : (
        <Section pad0 name={sectionJumbotronSc5.contentfulid}>
          <Jumbotron
            jumbotronBg={jumbotronBgSc5}
            topSpaceMin
            bottomSpaceMin={shortName !== 'i-90' ? false : true}
            flexSecondColumnCenter={shortName !== 'i-90' ? true : false}
            hideMdFirst
            columnSmallerImage
            backgroundRepeat
            redLineBorder
            shortName={shortName}
            buttonBigMargin
            firstColumn={
              <>
                {shortName === 'n-400' ? (
                  <img
                    className="e-img__jumbotron"
                    src={require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/product-sc5-${shortName}.png`).default}
                    alt=""
                    style={{
                      maxWidth: '100%',
                      position: 'relative',
                      bottom: '-14%',
                      maxHeight: '330px',
                    }}
                  />
                ) : shortName === 'i-765' ? (
                  <div
                    className="e-img__jumbotron"
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      background: `url(${require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/product-sc5-${shortName}.png`).default}) 0`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPositionX: '48%',
                      backgroundPositionY: '5%',
                    }}
                  />
                ) : (
                  <div
                    className="e-img__jumbotron"
                    alt=""
                    style={{
                      height: '100%',
                      width: '100%',
                      background: `url(${require(`../../images/${process.env.TEMPLATE}/style-scheme-${process.env.STYLE_SCHEME}/product-sc5-${shortName}.png`).default}) 0`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPositionX: '90%',
                      backgroundSize: 'contain',
                    }}
                  />
                )}
              </>
            }
            secondColumn={
              <>
                <Title
                  margin={'margBot0'}
                  content={sectionJumbotronSc5.content[0].firstColumn[0].name}
                />
                <Title
                  size="tiny"
                  content={sectionJumbotronSc5.content[0].firstColumn[0].formText}
                />
                {shortName === 'i-90' && (
                  <Button
                    type="brand"
                    iconArrow
                    width="maxContent"
                    dataqa={sectionJumbotronSc5.content[0].firstColumn[0].dataqa}
                    link={sectionJumbotronSc5.content[0].firstColumn[0].btnLink}
                    content={sectionJumbotronSc5.content[0].firstColumn[0].btnText}
                  />
                )}
                {sectionJumbotronSc5.content[0].firstColumn[0].bodyText && <RichText content={sectionJumbotronSc5.content[0].firstColumn[0].bodyText} />}
                {shortName !== 'i-90' && (
                  <Button
                    type="brand"
                    width="maxContent"
                    iconArrow
                    dataqa={sectionJumbotronSc5.content[0].firstColumn[0].dataqa}
                    link={sectionJumbotronSc5.content[0].firstColumn[0].btnLink}
                    content={sectionJumbotronSc5.content[0].firstColumn[0].btnText}
                  />
                )}
                {shortName === 'i-765' && (
                  <img
                    className="e-img__jumbotron hide-mobile"
                    width="97"
                    src={require(`../../images/shared/icons/money-back.png`).default}
                    alt=""
                    style={{ position: 'absolute', right: 0, bottom: '20px', width: '97px' }}
                  />
                )}
              </>
            }
          />
        </Section>
      )}

      <Section sc1 name={sectionSteps.contentfulid}>
        <Wrapper middle>
          <Title tag="h2" fontStyle="centered" size="regular" content={sectionSteps.title} />
          <Steps
            cardsFlexDivs
            cardsIconWidth={80}
            cardsIconHeight={80}
            cardsIconCenter={true}
            sectionSteps={sectionSteps}
            iconStepType={'step'}
            numSeparate
          />
        </Wrapper>
      </Section>
      {sectionInfo ? (
        <Section padTop0 light name={sectionInfoContent.contentfulid}>
          <Wrapper middle>
            <CardGroup>
              <TextMediaRows variantGreen={true} data={sectionInfoContent.content[0].cards} />
            </CardGroup>
          </Wrapper>
        </Section>
      ) : (
        <Section padTop0 light name={sectionFaqs.contentfulid}>
          <Wrapper middle>
            <CardGroup>
              {sectionFaqs.content[0].cards.map((card, i) => {
                return (
                  <Card
                    borderRadius
                    white
                    shadow
                    headPrimary
                    headPadSmall
                    key={i}
                    col={12}
                    colLg={12}
                    headContent={<Title size="small" content={card.headText} />}
                  >
                    {card.bodyText && <RichText content={card.bodyText} />}
                  </Card>
                );
              })}
            </CardGroup>
          </Wrapper>
        </Section>
      )}

      {stepServices && (
        <Section sc1 name={sectionStepsServices.contentfulid}>
          <Wrapper middle>
            <Title
              tag="h2"
              fontStyle="centered"
              size="regular"
              content={sectionStepsServices.title}
            />
            <Steps
              cardsFlexDivs
              cardsIconWidth={80}
              cardsIconHeight={80}
              cardsIconCenter={true}
              sectionSteps={sectionStepsServices}
              iconStepType={'step-services'}
              cardsTitle
              numSeparate
            />
          </Wrapper>
        </Section>
      )}

      <SecureSection sectionSecure={sectionSecure} />

      {sectionAdvantagesCompare && (
        <Section sc1 name={sectionCompare.contentfulid}>
          <Wrapper middle>
            <Title tag="h2" fontStyle="centered" size="regular" content={sectionCompare.title} />
            <Compare data={sectionCompare.content[0].cards} />
          </Wrapper>
        </Section>
      )}

      {allApplications && (
        <Section padBottom0 name={sectionApplications.contentfulid}>
          <Wrapper>
            <Title
              color="grey"
              fontWeight="normal"
              fontStyle="centered"
              size="tiny"
              content={sectionApplications.subtitle}
            />
            <Title
              tag="h2"
              fontStyle="centered"
              size="regular"
              content={sectionApplications.title}
            />
          </Wrapper>
        </Section>
      )}
    </Layout>
  );
};

export default Product;
