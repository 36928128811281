import React from 'react';
import { Card } from '../Card/card';
import { CardGroup } from '../CardGroup/cardGroup';
import RichText from '../RichText/richText';
import { Title } from '../../elements/Title/title.js';
import { Image } from '../../elements/Image/image';
import classNames from 'classnames';
import './textMediaRows.scss';
import { Button } from '../../elements/Button/button';

const imageInfoFirst = require(`../../../images/shared/image-info-1.png`).default;
const imageInfoSecond = require(`../../../images/shared/image-info-2.png`).default;

export const TextMediaRows = (props) => {
  const textMedia = classNames('m-text-media-rows');
  const textMediaText = classNames('m-text-media-rows__card-text');
  const textMediaImage = classNames('m-text-media-rows__card-image');
  const textMediaContainer = classNames('m-text-media-rows__container');

  return (
    <div className={textMedia}>
      <CardGroup>
        {props.data.map((item, i) => {
          return (
            <Card col={12} classNames={classNames(textMediaContainer)} key={i}>
              <Card
                col={12}
                colMd={6}
                bodyPad0
                classNames={classNames(textMediaText, i % 2 === 0 ? 'order0' : 'order1')}
              >
                <Title size="small" fontWeigth={'light'} content={item.headText} />
                <Title fontWeigth={'normal'} content={item.name} />
                {item.bodyText && <RichText medium content={item.bodyText} />}
                <Button
                  type={props.variantGreen ? 'variantGreen' : 'brand'}
                  size="large"
                  iconArrow
                  dataqa={item.dataqa}
                  link={item.btnLink}
                  content={item.btnText}
                />
              </Card>
              <Card
                col={12}
                colMd={6}
                bodyPad0
                classNames={classNames(textMediaImage, i % 2 === 0 ? 'order1' : 'order0')}
              >
                <Image
                  src={i % 2 === 0 ? imageInfoFirst : imageInfoSecond}
                  style={{ maxWidth: '100%' }}
                />
              </Card>
            </Card>
          );
        })}
      </CardGroup>
    </div>
  );
};
